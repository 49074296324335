import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Loading = styled.div`
  font-size: 4rem;
  font-style: italic;
  font-family: Thasadith;
  text-align: center;
`;

export const AuthorBox = styled.div`
  background-color: ${c.grayPaper};
  padding: 30px;
  border-radius: 8px;

  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
  }

  @media (min-width: 1200px) {
    padding: 60px;
  }

  &:not(:last-of-type) {
    margin-bottom: 30px;

    @media (min-width: 1200px) {
      margin-bottom: 60px;
    }
  }
`;

export const AuthorImage = styled.img`
  display: block;
  width: 100px;
  height: auto;
  margin-bottom: 20px;

  @media (min-width: 500px) {
    padding: 0 30px 0 0;
    float: left;
    width: 150px;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    
  }

  @media (min-width: 1200px) {
    padding: 0;
  }
`;

export const AuthorBio = styled.div`
  font-size: 2.4rem;

  @media (min-width: 768px) {
    font-size: 3rem;
    flex: 1;
  }

  @media (min-width: 1200px) {
    margin-left: 60px;
  }
`;

export const AuthorName = styled.h2`
  font-family: Thasadith;
  margin-bottom: 10px;
`;

export const AuthorBioText = styled.p`
  font-style: italic;
  line-height: 119%;

  @media (min-width: 1200px) {
    text-align: justify;
  }
`;
