import React from "react"
// Components
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container1200 } from '../components/Container1200';
import { PageTitle } from '../components/PageTitle';
// Images
import metaImage from '../images/icons/favicon-512.png';
// Style
import {
  AuthorBio,
  AuthorBox,
  AuthorName,
  AuthorBioText,
  AuthorImage
} from '../styles/pages-styles/author-bios.styles';

export const query = graphql`
  query AuthorBiosQuery {
    allSanityAuthor {
      edges {
        node {
          slug {
            current
          }
          name
          bio
          image {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`;

const AuthorBiosPage = ({ data }) => {

  return (
    <Layout>
      <SEO
        image={`https://www.ambitiousbeaver.com${metaImage}`}
        path="/author-bios"
        title="Author Bios"
      />
      <Container1200>
        <PageTitle>Author Bios</PageTitle>
        {data.allSanityAuthor.edges.map(({ node: author }) => {
          return (
            <AuthorBox key={author.slug.current}>
              <AuthorImage
                src={author.image.asset.fluid.src}
                alt={`${author.name} portrait`}
              />
              <AuthorBio>
                <AuthorName>{author.name}</AuthorName>
                <AuthorBioText>{author.bio}</AuthorBioText>
              </AuthorBio>
            </AuthorBox>
          )
        })}
      </Container1200>
    </Layout>
  )
}

export default AuthorBiosPage
